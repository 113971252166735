var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _vm.original
            ? _c(
                "gov-body",
                [
                  _vm._v("\n    For service\n    "),
                  _c("gov-link", {
                    attrs: {
                      to: {
                        name: "services-show",
                        params: { service: _vm.original.id }
                      }
                    },
                    domProps: { textContent: _vm._s(_vm.original.name) }
                  }),
                  _vm._v(".\n  ")
                ],
                1
              )
            : _c("gov-body", [_vm._v(" For a new service. ")]),
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }),
                      _vm.original
                        ? _c(
                            "gov-table-header",
                            { attrs: { scope: "column" } },
                            [_vm._v("From")]
                          )
                        : _vm._e(),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v(_vm._s(_vm.original ? "To" : "New"))
                      ])
                    ],
                    1
                  ),
                  _vm.service.hasOwnProperty("type")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Type")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", { attrs: { break: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("ucfirst")(
                                      _vm._f("originalExists")(
                                        _vm.original.type
                                      )
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm._f("ucfirst")(_vm.service.type)))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("URL")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", { attrs: { break: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.url)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm.service.url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("name")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Name")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.name)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("slug")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Slug")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.slug)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.slug))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("organisation_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Organisation")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty("organisation_id")
                                    ? _c(
                                        "gov-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "organisations-show",
                                              params: {
                                                organisation:
                                                  _vm.original.organisation_id
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.original.organisation.name
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              _c(
                                "gov-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "organisations-show",
                                      params: {
                                        organisation:
                                          _vm.service.organisation_id
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.service.organisation.name || ""
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("intro")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Intro")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.intro)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.intro))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("status")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Status")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm._f("status")(_vm.original.status))
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm._f("status")(_vm.service.status)))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("score")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Quality Score")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm._f("score")(_vm.original.score))
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm._f("score")(_vm.service.score)))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("ends_at")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("End date")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm._f("endsAt")(_vm.original.ends_at))
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(_vm._f("endsAt")(_vm.service.ends_at))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("is_free")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Is free")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm._f("isFree")(_vm.original.is_free))
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(_vm._f("isFree")(_vm.service.is_free))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("eligibility_types")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Eligibility")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                _vm._l(_vm.eligibilityTypes, function(
                                  rootTaxonomy
                                ) {
                                  return _vm.eligibilityTaxonomyChanged(
                                    rootTaxonomy
                                  ) ||
                                    _vm.eligibilityCustomChanged(rootTaxonomy)
                                    ? _c(
                                        "div",
                                        { key: rootTaxonomy.id },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "govuk-!-font-weight-bold"
                                            },
                                            [_vm._v(_vm._s(rootTaxonomy.name))]
                                          ),
                                          Array.isArray(
                                            _vm.service.eligibility_types
                                              .taxonomies
                                          )
                                            ? _c("ck-taxonomy-tree", {
                                                attrs: {
                                                  taxonomies:
                                                    rootTaxonomy.children,
                                                  checked: _vm.original
                                                    ? _vm.original
                                                        .eligibility_types
                                                        .taxonomies
                                                    : [],
                                                  error: false,
                                                  filteredTaxonomyIds: _vm.updatedServiceEligibilities(),
                                                  disabled: true
                                                }
                                              })
                                            : _vm._e(),
                                          _vm.eligibilityCustomChanged(
                                            rootTaxonomy
                                          )
                                            ? _c("gov-body", [
                                                _vm._v(
                                                  "Custom Value:\n              " +
                                                    _vm._s(
                                                      _vm.original
                                                        .eligibility_types
                                                        .custom[
                                                        _vm.slugify(
                                                          rootTaxonomy.name
                                                        )
                                                      ]
                                                    )
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                }),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            _vm._l(_vm.eligibilityTypes, function(
                              rootTaxonomy
                            ) {
                              return _vm.eligibilityTaxonomyChanged(
                                rootTaxonomy
                              ) || _vm.eligibilityCustomChanged(rootTaxonomy)
                                ? _c(
                                    "div",
                                    { key: rootTaxonomy.id },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "govuk-!-font-weight-bold"
                                        },
                                        [_vm._v(_vm._s(rootTaxonomy.name))]
                                      ),
                                      Array.isArray(
                                        _vm.service.eligibility_types.taxonomies
                                      )
                                        ? _c("ck-taxonomy-tree", {
                                            attrs: {
                                              taxonomies: rootTaxonomy.children,
                                              checked:
                                                _vm.service.eligibility_types
                                                  .taxonomies,
                                              error: false,
                                              filteredTaxonomyIds: _vm.updatedServiceEligibilities(),
                                              disabled: true
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.eligibilityCustomChanged(rootTaxonomy)
                                        ? _c("gov-body", [
                                            _vm._v(
                                              "Custom Value:\n              " +
                                                _vm._s(
                                                  _vm.service.eligibility_types
                                                    .custom[
                                                    _vm.slugify(
                                                      rootTaxonomy.name
                                                    )
                                                  ]
                                                )
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            }),
                            0
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("fees_url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Fees URL")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", { attrs: { break: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.fees_url
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm.service.fees_url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("fees_text")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Fees text")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.fees_text
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.fees_text))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("wait_time")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Wait time")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.wait_time
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.wait_time))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("description")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Description")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.toHtml(_vm.original.description)
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("gov-table-cell", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.toHtml(_vm.service.description)
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("testimonial")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Testimonial")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.testimonial
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.testimonial))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("video_embed")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Video embed")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.video_embed
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.video_embed))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("contact_name")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Contact name")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.contact_name
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.contact_name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("referral_url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Referral URL")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", { attrs: { break: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.referral_url
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm.service.referral_url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("useful_infos")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Useful infos")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty("useful_infos") &&
                                  Array.isArray(_vm.original.useful_infos)
                                    ? _c(
                                        "gov-list",
                                        _vm._l(
                                          _vm.original.useful_infos,
                                          function(usefulInfo, index) {
                                            return _c(
                                              "li",
                                              { key: "useful_info." + index },
                                              [
                                                _c("gov-heading", [
                                                  _vm._v(
                                                    _vm._s(usefulInfo.title)
                                                  )
                                                ]),
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.toHtml(
                                                        usefulInfo.description
                                                      )
                                                    )
                                                  }
                                                }),
                                                index <
                                                _vm.original.useful_infos
                                                  .length -
                                                  1
                                                  ? _c("gov-section-break", {
                                                      attrs: {
                                                        visible: "",
                                                        size: "l"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              Array.isArray(_vm.service.useful_infos)
                                ? _c(
                                    "gov-list",
                                    _vm._l(_vm.service.useful_infos, function(
                                      usefulInfo,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        { key: "useful_info." + index },
                                        [
                                          _c("gov-heading", [
                                            _vm._v(_vm._s(usefulInfo.title))
                                          ]),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.toHtml(
                                                  usefulInfo.description
                                                )
                                              )
                                            }
                                          }),
                                          index <
                                          _vm.service.useful_infos.length - 1
                                            ? _c("gov-section-break", {
                                                attrs: {
                                                  visible: "",
                                                  size: "l"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("offerings")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Offerings")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty("offerings") &&
                                  Array.isArray(_vm.original.offerings)
                                    ? _c(
                                        "gov-list",
                                        { attrs: { bullet: "" } },
                                        _vm._l(_vm.original.offerings, function(
                                          ref
                                        ) {
                                          var offering = ref.offering
                                          var order = ref.order
                                          return _c(
                                            "li",
                                            {
                                              key:
                                                "ServiceOffering::Original::" +
                                                order
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(offering) +
                                                  "\n            "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              Array.isArray(_vm.service.offerings)
                                ? _c(
                                    "gov-list",
                                    { attrs: { bullet: "" } },
                                    _vm._l(_vm.service.offerings, function(
                                      ref
                                    ) {
                                      var offering = ref.offering
                                      var order = ref.order
                                      return _c(
                                        "li",
                                        {
                                          key: "ServiceOffering::New::" + order
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(offering) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("contact_email")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Contact email")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.contact_email))
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.contact_email))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("contact_phone")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Contact phone")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.contact_phone
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.contact_phone))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("cqc_location_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("CQC Location ID")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.cqc_location_id
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.cqc_location_id))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("social_medias")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Social medias")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                { attrs: { break: "" } },
                                [
                                  _vm.original.hasOwnProperty(
                                    "social_medias"
                                  ) && Array.isArray(_vm.original.social_medias)
                                    ? _c(
                                        "gov-list",
                                        _vm._l(
                                          _vm.original.social_medias,
                                          function(socialMedia, index) {
                                            return _c(
                                              "li",
                                              { key: "social_media." + index },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "govuk-!-font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "socialMediaType"
                                                        )(socialMedia.type)
                                                      ) + ":"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(socialMedia.url) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            { attrs: { break: "" } },
                            [
                              Array.isArray(_vm.service.social_medias)
                                ? _c(
                                    "gov-list",
                                    _vm._l(_vm.service.social_medias, function(
                                      socialMedia,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        { key: "social_media." + index },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "govuk-!-font-weight-bold"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("socialMediaType")(
                                                    socialMedia.type
                                                  )
                                                ) + ":"
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(socialMedia.url) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("referral_email")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Referral email")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.referral_email))
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.referral_email))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("referral_method")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Referral method")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("referralMethod")(
                                      _vm._f("originalExists")(
                                        _vm.original.referral_method
                                      )
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm._f("referralMethod")(
                                  _vm.service.referral_method
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("tags")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Tags")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty("tags") &&
                                  Array.isArray(_vm.original.tags)
                                    ? _c(
                                        "gov-list",
                                        { attrs: { bullet: "" } },
                                        _vm._l(_vm.original.tags, function(
                                          tag,
                                          index
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              key:
                                                "ServiceTag::Original::" + index
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(tag.label) +
                                                  "\n            "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              Array.isArray(_vm.service.tags)
                                ? _c(
                                    "gov-list",
                                    { attrs: { bullet: "" } },
                                    _vm._l(_vm.service.tags, function(
                                      tag,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        { key: "ServiceTag::New::" + index },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(tag.label) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("category_taxonomies")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Category taxonomies")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty(
                                    "category_taxonomies"
                                  ) &&
                                  Array.isArray(
                                    _vm.original.category_taxonomies
                                  )
                                    ? _c(
                                        "gov-list",
                                        { attrs: { bullet: "" } },
                                        _vm._l(
                                          _vm.original.category_taxonomies,
                                          function(taxonomy, index) {
                                            return _c(
                                              "li",
                                              {
                                                key:
                                                  "category_taxonomies." + index
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.taxonomyName(
                                                        _vm.findTaxonomy(
                                                          taxonomy.id
                                                        )
                                                      )
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              Array.isArray(_vm.service.category_taxonomies)
                                ? _c(
                                    "gov-list",
                                    { attrs: { bullet: "" } },
                                    _vm._l(
                                      _vm.service.category_taxonomies,
                                      function(taxonomy, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: "category_taxonomies." + index
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.taxonomyName(
                                                    _vm.findTaxonomy(taxonomy)
                                                  )
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("referral_button_text")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Referral button text")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm.original.referral_button_text)
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.service.referral_button_text))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("show_referral_disclaimer")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Show referral disclaimer")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("showReferralDisclaimer")(
                                      _vm.original.show_referral_disclaimer
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm._f("showReferralDisclaimer")(
                                  _vm.service.show_referral_disclaimer
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("logo_file_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Logo")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src: _vm.apiUrl(
                                      "/services/" +
                                        _vm.service.id +
                                        "/logo.png?v=" +
                                        _vm.requestedAt
                                    ),
                                    alt: "Service logo"
                                  }
                                })
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm.service.id
                              ? _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src:
                                      _vm.logoDataUri ||
                                      _vm.apiUrl(
                                        "/services/" +
                                          _vm.service.id +
                                          "/logo.png?update_request_id=" +
                                          _vm.updateRequestId
                                      ),
                                    alt: "Service logo"
                                  }
                                })
                              : _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src:
                                      _vm.logoDataUri ||
                                      _vm.apiUrl(
                                        "/services/new/logo.png?update_request_id=" +
                                          _vm.updateRequestId
                                      ),
                                    alt: "Service logo"
                                  }
                                })
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.service.hasOwnProperty("gallery_items")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Gallery items")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                { staticStyle: { width: "25%" } },
                                [
                                  _vm.original.hasOwnProperty(
                                    "gallery_items"
                                  ) && Array.isArray(_vm.original.gallery_items)
                                    ? _c("ck-carousel", {
                                        attrs: {
                                          "image-urls": _vm.imageUrls(
                                            _vm.original
                                          )
                                        }
                                      })
                                    : _c("gov-body", [_vm._v("-")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            {
                              style: _vm.original
                                ? "width: 25%;"
                                : "width: 50%;"
                            },
                            [
                              Array.isArray(_vm.service.gallery_items)
                                ? _c("ck-carousel", {
                                    attrs: {
                                      "image-urls": _vm.serviceGalleryItems
                                    }
                                  })
                                : _c("gov-body", [_vm._v("-")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }